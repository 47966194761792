import { TweenMax, TimelineMax, Elastic } from 'gsap'

class Cursor {
  constructor () {
    this.cursor =  document.querySelector('.customCursor')
    this.cursorCircle = this.cursor.querySelector('.Cursor')
    this.targets = document.querySelectorAll('.cursorHover')

    this.cursorTarget = document.querySelector('.CursorTarget')
    this.targetEl = document.querySelector('.targetCursor')
    this.cursorText = document.querySelector('.cursorText')

    this.targetCursorIsOpen = false
    this.needTargetCursor = false

    this.setCursorTl()
    this.setCursor()
  }

  reset(){
    this.targetCursorIsOpen = false
    this.needTargetCursor = false
    this.hideTargetCursor()
  }

  toggleTargetCursor(show){
    this.needTargetCursor = show

    if(show)
      this.showTargetCursor()
    else
      this.hideTargetCursor()
  }

  setCursorTl(){
    this.pointerTl = new TimelineMax({
      paused: true,
      onReverseComplete: _ => {
        if(this.needTargetCursor && !this.targetCursorIsOpen) this.showTargetCursor()
      }
    })
    this.pointerTl.to(this.cursorCircle, 0.3, {width: 50, height: 50, borderWidth: 2})
  }

  showTargetCursor(){
    this.targetCursorIsOpen = true
    TweenMax.set(this.cursorCircle, {autoAlpha:0})
    TweenMax.set(this.cursorTarget, {autoAlpha:1})
    TweenMax.to(this.cursorTarget, 0.3, {width: 50, height: 50, borderWidth: 2, onComplete: _ => {
      TweenMax.to(this.targetEl, 0.3, {autoAlpha: 1})
      TweenMax.to(this.cursorText, 0.3, {autoAlpha: 1})
    }})
  }

  newTargetCursor(alreadyOpen, close, cb){
    if(window.isAboutVisible && !close)
      return

    TweenMax.to(this.cursorText, 0.3, {autoAlpha: 0})
    TweenMax.to(this.cursorTarget, 0.3, {autoAlpha: 0, width: 80, height: 80})
    TweenMax.to(this.targetEl, 0.3, {width: 50, height: 50, borderWidth: 2, onComplete: _ => {

      if(close){
        // if(alreadyOpen){
        //  TweenMax.set(this.cursorTarget, {autoAlpha:0})
        //  if(cb) cb()
        // }
        // else{
          TweenMax.set([this.cursorTarget, this.targetEl], {clearProps: 'all', onComplete: _ => {
            TweenMax.set(this.cursorCircle, {autoAlpha:1})
            TweenMax.set(this.cursorTarget, {autoAlpha:0})
            this.targetCursorIsOpen = false
            if(cb)
              cb()
          }})
        // }
      }
      else {
        TweenMax.set(this.cursorTarget, {autoAlpha: 1, width: 50, height: 50})
        TweenMax.set(this.targetEl, {autoAlpha: 1, width: 8, height: 8, borderWidth: 4})
      }
    }})
  }

  hideTargetCursor(alreadyOpen, cb){
    this.newTargetCursor(alreadyOpen, true, cb)
  }

  setCursor(){
    document.addEventListener('mousemove', e => {
      this.onMove(e.clientX, e.clientY)

      for(let i = 0; i < this.targets.length; i++){
        if(this.targets[i].matches(':hover')){
          if(this.targets[i].classList.contains('aboutLink') ||
            this.targets[i].classList.contains('startButton') ||
            this.targets[i].classList.contains('replay-btn')){
            TweenMax.to([this.cursor, this.cursorText], 0.2, {autoAlpha: 0})
          }
          else{
            if(this.targetCursorIsOpen){
              this.hideTargetCursor(true, _ => {
                this.pointerTl.play()
              })
            }
            else{
              if(this.targets[i].classList.contains('slider'))
                TweenMax.to('.arrowCursor ', 0.3, {autoAlpha: 1})

              this.pointerTl.play()
            }
          }

          return
        }
        else{
          TweenMax.to(this.cursor, 0.2, {autoAlpha: 1})
          if(this.targets[i].classList.contains('slider'))
            TweenMax.to('.arrowCursor ', 0.3, {autoAlpha: 0})
          this.pointerTl.reverse()
        }
      }
    })
  }

  onMove(x, y) {
    TweenMax.set(this.cursor, {x: x, y: y})
    TweenMax.to(this.cursorText, 0.5, {x: x - 100, y: y + 40})
  }

  cursorGrab(grab){
    if(grab)
      TweenMax.to(this.cursor, 0.5, {scale: 1.5, ease: Elastic.easeOut.config(1, 0.5)})
    else
      TweenMax.to(this.cursor, 0.5, {scale: 1, ease: Elastic.easeOut.config(1, 0.5)})
  }
}

export default Cursor
