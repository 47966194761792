import Swiper from 'swiper';

class Slider {
  constructor (cursor) {
    this.cursor = cursor
    this.setSlider()
  }

  setSlider() {
    let sliderEl = document.querySelector('.slider'),
        slides = sliderEl.querySelectorAll('.swiper-slide')

    var mySwiper = new Swiper(sliderEl, {
      spaceBetween: window.isMobile ? 10 : 50,
      slidesPerView: window.isMobile ? 1.2 : 'auto',
      initialSlide: slides.length-1,
      centeredSlides: true,
      touchStartPreventDefault: false,
      on: {
        touchStart: _ => {
          this.cursor.cursorGrab(true)
        },
        touchEnd: _ => {
          this.cursor.cursorGrab(false)
        }
      }
    })
  }
}

export default Slider
