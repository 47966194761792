
const THREE = require('three')
import Ammo from 'three/examples/js/libs/ammo'
import {TweenLite} from 'gsap'
import { Power4, Power0 } from 'gsap/TweenLite';

// var PHYSIC_ENGINE = true
// var this.physicsWorld
// let this.rigidBodies = []
// var tmpTrans
var pos = new THREE.Vector3()
var quat = new THREE.Quaternion()
// // petanque
// var BALL_RADIUS = 1.4
// var ballMass = 1.5
// var ballMaterial = new THREE.MeshPhongMaterial( { color: '#565656' } )
// const ballMesh = new THREE.Mesh(new THREE.SphereBufferGeometry(ballRadius), ballMaterial)
// ballMesh.castShadow = true
// ballMesh.receiveShadow = true

const TYPE_COCHONNET = 0
const TYPE_NORMAL = 1
const TYPE_THROWABLE = 2

const BALL_RADIUS = 2

const BALL_MESH = new THREE.Mesh(new THREE.SphereBufferGeometry(BALL_RADIUS, 64), new THREE.MeshPhongMaterial({color: '#685b74'}))


const BALL_COCHONNET_MESH = new THREE.Mesh(new THREE.SphereBufferGeometry(BALL_RADIUS * .55, 64), new THREE.MeshPhongMaterial({color: '#6c8fbd', shininess: 0}))


const BALL_THROWABLE_MESH = new THREE.Mesh(new THREE.SphereBufferGeometry(BALL_RADIUS, 64), new THREE.MeshPhongMaterial({color: '#dba753', shininess: 0}))


const ballTerrainPos = new THREE.Vector3(64, -474, -720)


const PUISSANCE_DE_TIR = 100


const GRAVITY_FROM = -50
const GRAVITY_T0 = -50

class BallGame{
    constructor(Cursor, sceneContext){

        this.sceneContext = sceneContext
        this.Cursor = Cursor

        this.Ammo = null
        this.window = {x: 0, y: 0}

        this.scene = null
        this.camera = null

        this.physicsWorld = null
        this.tmpTrans = null
        this.rigidBodies = []

        this.mouseCoords = new THREE.Vector2()
        this.raycaster = new THREE.Raycaster()

        this.colGroupPlane = 1
        this.colGroupRedBall = 2
        this.colGroupGreenBall = 4

        this.throwBall = this.throwBall.bind(this)

    }

    start(next){
        const self = this
        Ammo().then(AmmoLib => {
            this.Ammo = AmmoLib
            next()
        })
    }

    canPlay(){
        this.generateRandomBalls()
        this.attachEvents()
    }

    throwBall(event){
      let clientX = event.pageX ? event.pageX : event.clientX,
          clientY = event.pageY ? event.pageY : event.clientY

        if(this.sceneContext.canTriggerEvent == false){
            return
        }
        this.Cursor.newTargetCursor()

        let pos = new THREE.Vector3()
        this.mouseCoords.set(
            ( clientX / window.innerWidth ) * 2 - 1,
            - ( clientY / window.innerHeight ) * 2 + 1
        );
        this.raycaster.setFromCamera( this.mouseCoords, this.camera );

        var ballShape = new this.Ammo.btSphereShape( BALL_RADIUS );
        ballShape.setMargin( .05 );
        pos.copy( this.raycaster.ray.direction );
        pos.add( this.raycaster.ray.origin );
        quat.set( 0, 0, 0, 1 );
        var createdBall = this.createBall(pos, TYPE_THROWABLE);
        pos.copy( this.raycaster.ray.direction );
        pos.multiplyScalar(PUISSANCE_DE_TIR);
        createdBall.body.setLinearVelocity( new this.Ammo.btVector3( pos.x, pos.y, pos.z ) );
        this.scene.add(createdBall.object)

        // window.removeEventListener( 'mousedown', this.throwBall, false );
    }

    attachEvents(){
      if(window.isMobile && document.querySelector('body').classList.contains('iPhone'))
        document.querySelector('#canvasContainer').addEventListener('touchstart', this.throwBall, false );
      else
        window.addEventListener( 'mousedown', this.throwBall, false );
    }

    createFloor(){

        let floorPosition = new THREE.Vector3().copy(ballTerrainPos)
        floorPosition.z += -10
        floorPosition.y += -2

        let scale = {x: 218 * 2, y: .1, z: 356 * 2};
        let quat = {x: 0, y: 0, z: 0, w: 1};
        let mass = 0;

        //threeJS Section
        let blockPlane = new THREE.Mesh(new THREE.BoxBufferGeometry(), new THREE.MeshPhongMaterial({color: 0xa0afa4}));
        blockPlane.position.copy(floorPosition)
        blockPlane.scale.set(scale.x, scale.y, scale.z);
        this.scene.add(blockPlane);
        blockPlane.visible = false


        //Ammojs Section
        let transform = new this.Ammo.btTransform();
        transform.setIdentity();
        transform.setOrigin( new this.Ammo.btVector3(floorPosition.x, floorPosition.y, floorPosition.z))
        transform.setRotation( new this.Ammo.btQuaternion(quat.x, quat.y, quat.z, quat.w));
        let motionState = new this.Ammo.btDefaultMotionState(transform )
        let colShape = new this.Ammo.btBoxShape(new this.Ammo.btVector3( scale.x * 0.5, scale.y * 0.5, scale.z * 0.5))
        colShape.setMargin(0.05)
        let localInertia = new this.Ammo.btVector3(0, 0, 0 )
        colShape.calculateLocalInertia(mass, localInertia )
        let rbInfo = new this.Ammo.btRigidBodyConstructionInfo( mass, motionState, colShape, localInertia )
        let body = new this.Ammo.btRigidBody( rbInfo )
        this.physicsWorld.addRigidBody( body, this.colGroupPlane, this.colGroupRedBall)

        let positionForBalls = new THREE.Vector3().copy(floorPosition)
        positionForBalls.y += 4
        positionForBalls.z += 65


    }

    generateRandomBalls(){
        let count = 11
        let offsetMax = 50

        let around = new THREE.Vector3().copy(ballTerrainPos)
        around.y += 55

        for(let i = 0; i < count + 1; i++){
            let newPos = around.clone()

            newPos.x += 2 * offsetMax * Math.random() - offsetMax
            newPos.z += 2 * offsetMax * Math.random() - offsetMax
            newPos.y += 15 * (2 * Math.random() - 1)

            let createdBall = this.createBall(newPos, i === count ? TYPE_COCHONNET : TYPE_NORMAL)
            this.scene.add(createdBall.object)
        }

        let progress = {value: 0}
        const self = this
        // TweenLite.to(progress, 1, {
        //     value: 1,
        //     callbackScope: this,
        //     ease: Power0.easeNone,
        //     onUpdate(){
        //         this.physicsWorld.setGravity(new this.Ammo.btVector3(0, GRAVITY_FROM + (GRAVITY_T0 - GRAVITY_FROM) * progress.value, 0));
        //     },
        //     onComplete(){
        //         console.log('#####')
        //         console.log('## CAN THROW ##')
        //         console.log('#####')
        //     }
        // })

    }

    init(Scene){

        this.scene = Scene.scene
        this.camera = Scene.camera

        let collisionConfiguration  = new this.Ammo.btDefaultCollisionConfiguration(),
            dispatcher              = new this.Ammo.btCollisionDispatcher(collisionConfiguration),
            overlappingPairCache    = new this.Ammo.btDbvtBroadphase(),
            solver                  = new this.Ammo.btSequentialImpulseConstraintSolver();
        this.physicsWorld           = new this.Ammo.btDiscreteDynamicsWorld(dispatcher, overlappingPairCache, solver, collisionConfiguration);
        this.physicsWorld.setGravity(new this.Ammo.btVector3(0, GRAVITY_FROM, 0));

        this.tmpTrans = new this.Ammo.btTransform();

        this.createFloor()

        // let posRef = new THREE.Vector3().set(64, -474, -780)
        // posRef.y += 4
        // let ball1 = this.createBall(posRef)
        // this.scene.add(ball1.object)


        // this.attachEvents()

      }

    createBall(startPos = null, ballType = TYPE_NORMAL){

        let pos = startPos || {x: 0, y: 20, z: 0};
        let radius = BALL_RADIUS;
        let quat = {x: 0, y: 0, z: 0, w: 1};
        let mass = 1;
        //threeJS Section
        let ball = (ballType === TYPE_COCHONNET ? BALL_COCHONNET_MESH : (ballType === TYPE_THROWABLE ? BALL_THROWABLE_MESH : BALL_MESH)).clone()

        ball.position.set(pos.x, pos.y, pos.z);

        //Ammojs Section
        let transform = new this.Ammo.btTransform();
        transform.setIdentity();
        transform.setOrigin( new this.Ammo.btVector3( pos.x, pos.y, pos.z ) );
        transform.setRotation( new this.Ammo.btQuaternion( quat.x, quat.y, quat.z, quat.w ) );
        let motionState = new this.Ammo.btDefaultMotionState( transform );
        let colShape = new this.Ammo.btSphereShape( radius );
        colShape.setMargin( 0.05 );
        let localInertia = new this.Ammo.btVector3( 0, 0, 0 );
        colShape.calculateLocalInertia( mass, localInertia );
        let rbInfo = new this.Ammo.btRigidBodyConstructionInfo( mass, motionState, colShape, localInertia );
        let body = new this.Ammo.btRigidBody( rbInfo );
        this.physicsWorld.addRigidBody( body, this.colGroupRedBall, this.colGroupPlane | this.colGroupGreenBall | this.colGroupRedBall);

        ball.userData.physicsBody = body;
        this.rigidBodies.push(ball);


        body.setFriction( 100 );
        body.setRestitution(.8)
        body.setDamping(.3,0)
        return {object: ball, body: body}
    }


    updatePhysics( deltaTime ){
        this.physicsWorld.stepSimulation( deltaTime, 10 );
        for ( let i = 0; i < this.rigidBodies.length; i++ ) {
            let objThree = this.rigidBodies[ i ];
            let objAmmo = objThree.userData.physicsBody;
            let ms = objAmmo.getMotionState();
            if ( ms ) {
                ms.getWorldTransform( this.tmpTrans );
                let p = this.tmpTrans.getOrigin();
                let q = this.tmpTrans.getRotation();
                objThree.position.set( p.x(), p.y(), p.z());
                objThree.quaternion.set( q.x(), q.y(), q.z(), q.w() );
            }
        }
      }


}

export default BallGame

