var scrollMonitor = require("scrollmonitor")
import SplitText from './utils/SplitText.min.js'
import { TweenLMax, Elastic, Power2, Expo } from 'gsap'

class About {
  constructor (Cursor) {
    this.Cursor = Cursor
    this.about = document.querySelector('.about')
    this.targets = this.about.querySelectorAll('.aboutScroll')
    this.containerMonitor = scrollMonitor.createContainer(this.about)
    this.cursorTargetBeforeOpen = false
    this.boule = this.about.querySelector('.circle img')
    this.mainNav = document.querySelector('.Nav')

    this.preloadImg()
  }

  preloadImg(){
    this.bouleImgs = {
      boule: [],
      bouleBleu: []
    }

    for(let i = 1; i <= 90; i++) {
      let img = new Image()
      img.src = `./assets/boule/${i}.png`
      this.bouleImgs.boule.push(img)
    }

    for(let j = 1; j <= 90; j++) {
      let img = new Image()
      img.src = `./assets/bouleBleu/${j}.png`
      this.bouleImgs.bouleBleu.push(img)
    }
  }

  init(){
    this.cursorTargetBeforeOpen = this.Cursor.needTargetCursor
    this.Cursor.toggleTargetCursor(false)
    this.watchers = []
    this.splits = []
    this.setWatch()

    this.about.addEventListener('scroll', this.animateBall.bind(this))
  }

  destroy(){
    this.Cursor.toggleTargetCursor(this.cursorTargetBeforeOpen)
    this.about.removeEventListener('scroll', this.animateBall)

    for(let i = 0; i < this.watchers.length; i++){
      TweenMax.set(this.targets[i], {opacity: 0})
      this.watchers[i].destroy()

      if(this.targets[i].classList.contains('active'))
        this.targets[i].classList.remove('active')
      else if(this.targets[i].classList.contains('slider'))
        TweenMax.set([this.targets[i].querySelectorAll('img'), this.targets[i].querySelectorAll('.imageLayer')], {clearProps: "all"})

      this.about.scrollTop = 0
    }

    for(let j = 0; j < this.splits.length; j++)
      this.splits[j].revert()
  }

  animateBall(e){
    let percent = Math.floor(this.about.scrollTop / (this.about.scrollHeight - this.about.offsetHeight) * 100)
    percent = (percent < 1) ? 1 : ((percent > 90) ? 90 : percent)

    let folder = (this.about.classList.contains('pink') || this.about.classList.contains('yellow')) ? this.bouleImgs.bouleBleu : this.bouleImgs.boule

    if(folder[percent] && folder[percent].src)
      this.boule.src = folder[percent].src

    this.updateBackground(percent)
  }

  updateBackground(percent){
    let color = percent <= 33.3 ? '#6c8fbd' : ((percent >= 66.6) ? '#fdb1b3' : '#f9d395')
    let colorName = percent <= 33.3 ? 'blue' : ((percent >= 66.6) ? 'pink' : 'yellow')
    let navColor = (colorName === 'blue') ? 'yellow' : 'blue'

    TweenMax.to(this.about, 0.3, {backgroundColor: color})

    this.about.classList.remove('blue', 'pink', 'yellow')
    this.about.classList.add(colorName)

    this.mainNav.classList.remove('Nav--blue', 'Nav--pink', 'Nav--yellow')
    this.mainNav.classList.add('Nav--'+navColor)

    this.Cursor.cursor.classList.remove('Cursor--blue', 'Cursor--pink', 'Cursor--yellow')
    this.Cursor.cursor.classList.add('Cursor--'+navColor)
  }

  setWatch(){
    for(let i = 0; i < this.targets.length; i++){
      var elementWatcher = this.containerMonitor.create(this.targets[i])

      if(this.targets[i].classList.contains('slider')){
        elementWatcher.enterViewport(function() {
          let layers = this.watchItem.querySelectorAll('.imageLayer')
          TweenMax.to(layers, 0.8, {y: 0, ease: Expo.easeOut, onComplete: _ => {
            TweenMax.set(this.watchItem.querySelectorAll('img'), {opacity: 1})
            TweenMax.to(layers, 0.8, {y: '-100%', ease: Expo.easeOut})
          }})
        })
      }
      else{
        let split = null

        if(this.targets[i].classList.contains('title') || this.targets[i].classList.contains('sliderText'))
          split = new SplitText(this.targets[i], {type: 'words, chars', charsClass: 'letter'})
        else if(!this.targets[i].classList.contains('item'))
          split = new SplitText(this.targets[i], {type: 'lines', linesClass: 'line'})

        if(split !== null)
          this.splits.push(split)

        if(!this.targets[i].classList.contains('item'))
          TweenMax.set(this.targets[i], {opacity: 1})

        const revert = _ => {
          split.revert()
        }

        elementWatcher.enterViewport(function() {
          let speed = this.watchItem.getAttribute('data-speed') ? this.watchItem.getAttribute('data-speed') : 0.1

          if(split !== null && split.chars.length > 0)
            TweenMax.staggerTo(split.chars, 3, {opacity: 1, transform: "rotateY(0) rotateX(0) rotateZ(0)", ease: Elastic.easeOut.config(1, 0.75)}, 0.02, revert)
          else if(this.watchItem.classList.contains('item')){
            TweenMax.to(this.watchItem, 1, {opacity: 1, y: 0, onComplete: _ => {
              this.watchItem.classList.add('active')
            }})
          }
          else
            TweenMax.staggerTo(split.lines, 2.5, {opacity: 1, y: 0, ease: Elastic.easeOut.config(1, 1)}, speed, revert)
        });
      }

      this.watchers.push(elementWatcher)
    }
  }
}

export default About
