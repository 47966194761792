import FogShader from "./FogShader";

import 'three/examples/js/controls/OrbitControls'

var container, stats;
var camera, scene, renderer, materialDepth;
var sphereMesh;
var sunPosition = new THREE.Vector3( 0, 1000, - 1000 );
var screenSpacePosition = new THREE.Vector3();
var mouseX = 0, mouseY = 0;
var postprocessing = { enabled: true };
var orbitRadius = 200;
var bgColor = 0x000511;
var sunColor = 0xffee00;
// Use a smaller size for some of the god-ray render targets for better performance.
var godrayRenderTargetResolutionMultiplier = 1.0 / 4.0;

var a = null
var material
var mesh

function init() {
    container = document.createElement( 'div' );
    document.body.appendChild( container );
    //
    camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 300 );
    camera.position.set(0, 4, 8)
    scene = new THREE.Scene();

    //
    renderer = new THREE.WebGLRenderer();
    // renderer.setClearColor(new THREE.Color(1, 1, 1), 1)
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( window.innerWidth, window.innerHeight );
    container.appendChild( renderer.domElement );
    //

    scene.add(new THREE.AmbientLight(0xffffff, .5))

    var controls = new THREE.OrbitControls(camera)


    a = new THREE.Mesh(
        new THREE.BoxGeometry(2, 2, 2),
        new THREE.MeshBasicMaterial({color: 'red'})
    )

    scene.add(a)

    

        material = new THREE.RawShaderMaterial({
            uniforms: {
                "uTime": {value: 0},
                "tMap": {value: new THREE.TextureLoader().load('./plus/forest.jpg')},
                "uFogColor": {value: new THREE.Color()},
                "uFogNear": {value: 2},
                "uFogFar": {value: 100},
            },
            vertexShader: /*FogShader.vertexShader || */document.getElementById('vertexShader').textContent,
            fragmentShader: /*FogShader.fragmentShader || */document.getElementById('fragmentShader').textContent,
            
        })

        fetch('./plus/forest.json')
        .then(data => data.json())
        .then(data => {

            window.THREE = THREE
            
            const size = 8;
            const num = size * size;
            let offset = new Float32Array(num * 3);
            let random = new Float32Array(num * 3);
            for (let i = 0; i < num; i++) {
                // Layout in a grid
                offset.set([
                    (i % size - size * 0.5) * 2,
                    0,
                    (Math.floor(i / size) - size * 0.5) * 2,
                ], i * 3);
                random.set([Math.random(), Math.random(), Math.random()], i * 3);
            }

            var geometry = new THREE.BufferGeometry();

            // itemSize = 3 because there are 3 values (components) per vertex
            geometry.addAttribute( 'position', new THREE.BufferAttribute( new Float32Array(data.position), 3 ) );
            geometry.addAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(data.uv), 2 ) );
            geometry.addAttribute( 'offset', new THREE.InstancedBufferAttribute( offset, 3 ) );
            geometry.addAttribute( 'random', new THREE.InstancedBufferAttribute( random, 3 ) );
            mesh = new THREE.Mesh(geometry, material)
            mesh.position.set(6, 0, 2)
            scene.add(mesh)
        })
    
}

function animate() {
    requestAnimationFrame( animate, renderer.domElement );
    render();
}
function render(t) {

    // const t = Date.now()

    camera.lookAt(new THREE.Vector3())

    if (mesh) {
        // mesh.rotation.y += 0.003;
        // mesh.position.z = Math.sin(t * 0.0004) * 3.0;
    }

    if(material){
        material.uniforms.uTime.value = t * 0.001;
    }


    
  
    renderer.render( scene, camera );
}

const FogTest = {
    start(){
        init()
        animate()
    }
}

export default FogTest