
import { OBJLoader } from 'three-obj-mtl-loader'

const CAMERA_TARGET_DISTANCE = 4e-2

const DISTANCE_DETECTION = 5
const DETECTION_PRECISION = 1e-2

class Spline {
    constructor(camera, cameraTarget, scene, Steps){

        this.spline = null
        this.curve = null
        this.steps = []
        this.progress = 0
        this.camera = camera
        this.cameraTarget = cameraTarget
        this.cameraTarget.userData.positionReference = cameraTarget.position

     

        this.hasEnter = false

        this.scene = scene
        this.scene.add(this.cameraTarget)

        this.currentStep = null


        this.addSpline()



        this.cameraRef = new THREE.Mesh(
            new THREE.BoxGeometry(1, 1, 1),
            new THREE.MeshBasicMaterial({color: 'pink'})
        )
        this.cameraRef.visible = false
        this.cameraRef.geometry.computeBoundingSphere()
        
        this.scene.add(this.cameraRef)

        this.t = new THREE.Mesh(
            new THREE.BoxGeometry(1, 1, 1),
            new THREE.MeshBasicMaterial({color: 'red'})
        )
        this.t.visible = false
        this.scene.add(this.t)
        this.t.position.copy(this.curve.getPoint(.1))
        this.t.geometry.computeBoundingSphere()

        // after all
        this.setCamera(0) // init
        this.Steps = Steps


        window.SKIP = this.skipStep.bind(this)



        
    }

    reset(){
        this.steps.map(item => {
            item.isReached = false
            item.isSkipped = false
        })
    }

    skipStep(){
        if(this.currentStep === null) {
            console.error('No step to skip.')
            return
        }
        this.currentStep.isSkipped = true
        this.currentStep = null
        // for dev
        document.body.classList.remove('hasReached')
        this.Steps.hide()
    }

    hasReachedStep(){
        let stepReached = this.steps.some(step => step.isReached === false && this.progress >= step.at)

        for(let i = 0; i < this.steps.length; i++){
            let step = this.steps[i]

            if(this.progress > step.at && step.isSkipped === false){
                // console.log('has reached : ' + step.at)
                this.currentStep = step

                if(step.isReached === false){
                    step.isReached = true

                    // USELESS ??
                    // kill all tweens
                    // let tweens = this.scene.userData.currentTweens

                    // for(let i = 0; i < tweens.length; i++){
                    //     let tw = tweens[i]
                    //     tw.kill()
                    //     console.log('will kill', tw)
                    // }
                    step.callback()
                }
                return true
            }
        }

        return false
    }

    setSteps(steps){

        for(let i = 0; i < steps.length; i++){

            

            // Add steps helpers

            let stepHelper = new THREE.Mesh(
                new THREE.SphereBufferGeometry(4),
                new THREE.MeshBasicMaterial({
                    color: new THREE.Color('purple')
                })
            )
            stepHelper.visible = false
            let stepHelperPos = this.curve.getPoint(steps[i].at)
            stepHelper.position.copy(stepHelperPos)    
            this.scene.add(stepHelper)

            // Set steps
            this.steps.push({
                at: steps[i].at,
                callback: steps[i].callback,
                isReached: false,
                isSkipped: false,
                position: stepHelperPos,
                hasEnter: false
            })
        }

    }

    checkIntersectionsSteps(){

        let moving = this.progress

        for(let i = 0; i < this.steps.length; i++){
            let step = this.steps[i]
            let at = step.at
            let distance = Math.abs(at - moving)

            if(step.hasEnter == false && distance < DETECTION_PRECISION){
                alert('is in')
                step.hasEnter = true
            }

            if(step.hasEnter == true && distance > DETECTION_PRECISION){
                alert('is out')
                step.hasEnter = false
            }
            else {
             console.log(distance + ' === ' + DETECTION_PRECISION)

            }

        }

    }

    setCamera(frame){

        if(this.hasReachedStep()){
            // console.log('has to skip the current step')
            return this.progress
        }

        // this.checkIntersectionsSteps()

        frame = Math.max(0, frame)
        frame = Math.min(1, frame)
        const cameraPositionOnSpline = this.curve.getPoint(frame)
        cameraPositionOnSpline.y += 20

        this.progress = frame

        this.camera.position.copy(cameraPositionOnSpline)

        const camTargetPos = this.curve.getPoint(frame + CAMERA_TARGET_DISTANCE /* !!PEUT DEPASSER 1 et 0!! */)
        camTargetPos.y = this.camera.position.y

        this.cameraTarget.userData.computedPosition.copy(camTargetPos)
        // this.cameraTarget.position.add(this.cameraTarget.userData.rotationAroundCamera)


        // const vecToAdd = new THREE.Vector3().subVectors(camTargetPos, this.cameraTarget.userData.positionReference)
        // this.cameraTarget.position.add(vecToAdd)
        // this.cameraTarget.userData.positionReference = camTargetPos


        return this.progress
    }

    addSpline(){

        const points = [
            new THREE.Vector3(85, 50, -800),
            new THREE.Vector3(75, -185, -250),
            new THREE.Vector3(75, -185, -10),

            new THREE.Vector3(130, -205, -10),
            new THREE.Vector3(250, -260, 10),
            new THREE.Vector3(320, -280, 80),
            new THREE.Vector3(320, -410, 480),
            new THREE.Vector3(250, -410, 550),
            new THREE.Vector3(-100, -410, 550),
            new THREE.Vector3(-180, -410, 615),
            new THREE.Vector3(-180, -410, 960),
            new THREE.Vector3(-115, -410, 1010),
            // new THREE.Vector3(0, -410, 950), finally useless
            new THREE.Vector3(46, -410, 1010),
            new THREE.Vector3(/*65, -410, 720*/76, -410, 923)
        ]




        for(let i = 0; i < points.length; i++){

            let point = points[i]

            // MAKE SPLINE MATCH SCENE

            point.applyAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI)
            point.reflect(new THREE.Vector3(-1, 0, 0))

            point.y += -30

            // FOR DEV

            let splineDotHelper = new THREE.Mesh(
                new THREE.SphereBufferGeometry(2),
                new THREE.MeshBasicMaterial({color: 'blue'})
            )
            splineDotHelper.visible = false
            splineDotHelper.position.copy(points[i])
            this.scene.add(splineDotHelper)
        }

        this.curve = new THREE.CatmullRomCurve3(points)
          let geometry = new THREE.Geometry()
          geometry.vertices = this.curve.getPoints(points.length * 15)

          let material = new THREE.LineBasicMaterial({
            color: 0xFFFFFF
          })

          this.spline = new THREE.Line(geometry, material)
          this.spline.visible = false
          this.scene.add(this.spline)

    }

    get(){
        return this.spline
    }
}

export default Spline
