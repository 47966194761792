import { TweenMax, TimelineMax } from "gsap";
import Sound from "./Sound";
var MobileDetect = require('mobile-detect')

export default class Intro {
  constructor (settings, onIntroEnded = null) {
    window.isMobile = false

    var md = new MobileDetect(window.navigator.userAgent)

    if(md.mobile() !== null){
      document.querySelector('body').classList.add('mobile')
      window.isMobile = true
    }

    this.intro = document.querySelector('.Intro')
    this.video = this.intro.querySelector('.Intro-video')

    this.onIntroEnded = onIntroEnded

    this.setTimeline()

    this.playScene = settings.playScene
    this.stopScene = settings.stopScene

    // document.querySelector('.Intro-skip').addEventListener('click', () => {
    //   TweenMax.to('.Intro', .5, {
    //     autoAlpha: 0,
    //     onStart: () => {
    //       settings.onBeforeIntroDisappears()
    //     },
    //     onComplete: () => {
    //       this.pauseVideo()
    //       settings.onAfterIntroDisappears()
    //     }
    //   })
    // })

    TweenMax.set('.Intro-clouds-1', {
      scale: .6,
      autoAlpha: .2,
      x: '-20%',
      y: '15%',
      force3D: true
    })

    TweenMax.set('.Intro-clouds-2', {
      scale: .4,
      autoAlpha: .2,
      x: '20%',
      y: '12%',
      force3D: true
    })

    TweenMax.set('.Intro-clouds-3', {
      scale: .8,
      autoAlpha: .2,
      x: '10%',
      y: '-8%',
      force3D: true
    })


    TweenMax.set(['.Intro-start'], {
      autoAlpha: 0
    })

    let skipSplit = new SplitText('.Intro-skip', {type: 'chars', charsClass: 'letter'}),
        skipBtn = document.querySelector('.Intro-skip')

    document.querySelector('.startButton').addEventListener('click', e => {
      e.preventDefault()
      this.timeline.tweenTo('end')
      TweenMax.to('.Intro-start', .3, {
        autoAlpha: 0,
        onComplete: () => {
          TweenMax.staggerTo(skipSplit.chars, 0.3, {autoAlpha: 1, y: 0, delay: 0.5}, 0.05, _ => {
            skipBtn.classList.add('show')
          })
        }
      })
    })

    skipBtn.addEventListener('click', e => {
      e.preventDefault()
      this.video.onended()

      TweenMax.staggerTo(skipSplit.chars, 0.3, {autoAlpha: 0, y: -10}, 0.05, _ => {
        TweenMax.set(skipBtn, {autoAlpha: 0})
      })
    })


    this.video.onended = () => {

      // let hasChained = false

      // TweenMax.set('.Intro-video', {
      //   display: 'none'
      // })

      let tl = TweenMax.to('.Intro', .5, {
        autoAlpha: 0,
        onStart: () => {
          this.playScene()
          TweenMax.to(['.Nav-about', '.Nav-logoUN'], .5, {
            autoAlpha: 1
          })
        },

        // onUpdate: () => {
        //   if(hasChained === false && tl.progress() > .5){
        //     hasChained = true
        //   }
        // },

        onComplete: () => {
          TweenMax.set('.Intro', {
            display: 'none'
          })

          this.video.pause()
      this.onIntroEnded()

        }
      })
    }

    if(window.isMobile)
      this.video.onended()
  }

  animEnter () {
    this.timeline.tweenTo('visible')

    TweenMax.staggerTo(['.Intro-clouds-1', '.Intro-clouds-2', '.Intro-clouds-3'], 2, {
      scale: 1,
      autoAlpha: 1,
      x: '0%',
      y: '0%',
      force3D: true
    }, .5)
  }

  setTimeline () {
    this.timeline = new TimelineMax({
      paused: true,
      onComplete: () => {
        TweenMax.to('.Nav', .5, {
          autoAlpha: 1
        })
        TweenMax.to('.Intro-clouds', 1, {
          scale: .8,
          autoAlpha: 0,
          onStart: () => {
            this.video.play()
            sound.fadeInPlay()
          },
          delay: .5
        })
        TweenMax.to('.Intro .overlay', 1, {
          autoAlpha: 0,
          delay: .5
        })


        TweenMax.to('.Nav-soundControl', .5, {
          autoAlpha: 1,
        })
      }
    })

    let splitText = new SplitText('.Intro-content .step', {type: 'chars, words', charsClass: 'letter'}),
        distance = 50,
        time = 2,
        gap = 0.04

    TweenMax.set(splitText.chars, {autoAlpha: 0, y: distance})
    TweenMax.set('.Intro .dot', {
      autoAlpha: 0
    })

    this.timeline
    .addLabel('start')
    .call(_ => {
      let activeStep = document.querySelector('.Intro-content .step')
      activeStep.classList.toggle('active')
      TweenMax.to('.Intro-content .background', 1, {autoAlpha: 0.45})
    })
    .staggerTo(splitText.chars, time, {autoAlpha: 1, y: 0, ease: Elastic.easeOut.config(1, 0.75)}, gap)
    .to('.Intro .dot', .3, { autoAlpha: 1 }, '-=.8')
    .to('.Intro-start', .5, { autoAlpha: 1 }, '-=1.5')
    .addLabel('visible')
    .to('.Intro-content .background', 0.3, {autoAlpha: 0})
    .staggerTo(splitText.chars, time/3, {
      onStart: () => {
        TweenMax.to('.Intro .dot', .3, { autoAlpha: 0 })
      },
      autoAlpha: 0,
      y:-distance,
      ease: Elastic.easeOut.config(1, 0.75)
    }, gap / 4)
    .set('.Intro-content .background', {autoAlpha: 0})
    .addLabel('end')
  }

  onVideoLoaded (cb) {
    this.video.addEventListener('canplaythrough', () => {
      cb()
    })
  }

  playVideo () {
    this.video.play()
    console.log(this.video)
  }

  pauseVideo () {
    this.video.pause()
  }
}
