// hello
const THREE = require('three')
import 'three/examples/js/controls/OrbitControls'
import 'three/examples/js/loaders/GLTFLoader'
import 'three/examples/js/loaders/FBXLoader'
import 'three/examples/js/loaders/DRACOLoader'
import { OBJLoader } from 'three-obj-mtl-loader'
import 'three/examples/js/ShaderGodRays'
import 'three/examples/js/shaders/BokehShader2'
import 'three/examples/js/controls/DeviceOrientationControls'

import Ammo from 'three/examples/js/libs/ammo'


import {TweenLite, Power4, TweenMax, Linear} from 'gsap'

import BallGame from './BallGame'
import Spline from './Spline'
require('../../styles/index.scss')
import Steps from '../Steps'
import Intro from '../Intro'
import { isAbsolute } from 'path';
import { Power3 } from 'gsap/TweenLite';

let MAX_WHEEL_COUNT = 35
const HEAD_ROTATION = Math.PI * .5
const HEAD_ROTATION_X = HEAD_ROTATION
const HEAD_ROTATION_Y = HEAD_ROTATION * 1.5

var isObjectsLoaded = false;

var SHADOW_MAP_WIDTH = 2048, SHADOW_MAP_HEIGHT = 1024;

var req

let resetCount = 0

class Scene {
  constructor (cursor) {

    if(window.isMobile){
      MAX_WHEEL_COUNT *= .65
    }

    this.retrySplit = new SplitText('#resetScene', {type: 'chars', charsClass: 'letter'}),

    this.steps = new Steps()
    this.intro = new Intro({
      playScene: this.playScene.bind(this),
      stopScene: this.stopScene.bind(this)
    }, () => {
      window.animIntoScene()
    })

    this.cursor = cursor
    window.scene = this
    this.mouse = new THREE.Vector2()
    this.target = new THREE.Vector2()
    this.window = new THREE.Vector2(window.innerWidth, window.innerHeight)
    this.clock = new THREE.Clock()
    this.canTriggerEvent = true
    this.ballGame = new BallGame(cursor, this)
    this.ballGame.start(() => {
      this.init()
    })

    this.overlay = document.querySelector('.overlayWhite')

    window.animIntoScene = this.animIntoScene.bind(this)
    window.THREE = THREE
    window.camera = this.camera
    window.cameraTarget = this.cameraTarget
    window.TweenMax = TweenMax

  }

  destroy(){

    this.removeEvents()
    this.stopScene()

  }

  reset(){

    this.overlay.classList.add('active')
      setTimeout(() => {
        for(let i = 0; i < 2; i++){
          this.frame = this.spline.setCamera(0)
          this.mouse.x = this.mouse.y = 0
          this.computeVectorAroundCamera()
          this.applyCameraTargetPosition()
        }

      this.spline.reset()
      // document.querySelector('.replay-container').classList.toggle('show')
      TweenMax.staggerTo(this.retrySplit.chars, 0.3, {autoAlpha: 0, y: -10, delay: 0.5}, 0.05)
      this.cursor.reset()

      this.animIntoScene()

      setTimeout(() => {
        this.overlay.classList.remove('active')
      }, 500)
    }, 1000)


  }

  animIntoScene(){

    this.canTriggerEvent = false
    let progress = {value: 0}
    let to = .13

    TweenLite.to(progress, 2.3, {
      value: 1,
      onUpdate: () => {
        this.frame = this.spline.setCamera(progress.value * to)
        this.computeVectorAroundCamera()
      },

      onComplete: () => {
        this.canTriggerEvent = true
      }
    })
  }

  init(){
    this.setCamera()
    this.setScene()
    this.setFog()
    this.setSound()
    this.addWorld()
    // this.addDev World()
    this.addLight()
    this.addSpline()
    this.attachEvents()

    this.frame = this.spline.setCamera(0)

    this.ballGame.init(this)

    //this.animate()
  }


  enterAboutPage(next){
    this.canTriggerEvent = false

    let hasNext = false
    let tl = TweenLite.to(this.cameraTarget.userData.computedPosition, 2, {
      y: '+=500',
      ease: Power3.easeInOut,
      onStart: () => {
        console.log('START IT')
      },
      onComplete: () => {
        console.log('comp ')
      },
      onUpdate: () => {
        console.log('upd')
        let progress = tl.progress()
        if(progress > .6 && hasNext === false){
          hasNext = true
          next()
        }
      }
    })
  }

  exitAboutPage(next){
    next()
    TweenLite.to(this.cameraTarget.userData.computedPosition, 2, {
      y: '+=-500',
      ease: Power3.easeOut,
      callbackScope: this,
      onComplete(){
        this.canTriggerEvent = true
      }
    })
  }


  addPhysicsEngine(next){

    Ammo().then(AmmoLib => {
      _.Ammo = AmmoLib
      next()
    })

  }

  setCamera () {
    this.camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 1, 100000)
    this.cameraTarget = new THREE.Mesh(
      new THREE.SphereBufferGeometry(2),
      new THREE.MeshBasicMaterial({color: 'green', side: THREE.DoubleSide, transparent: true, opacity: .3})
    )
    this.cameraTarget.userData.rotationAroundCamera = new THREE.Vector3()
    this.cameraTarget.userData.computedPosition = new THREE.Vector3()

    this.cameraTarget.visible = false
    // Controls
    // this.controls = new THREE.OrbitControls(this.camera)
    // this.controls.enableZoom = false
    // this.controls.update()
  }

  setFog(){
    this.scene.fog = new THREE.Fog('#e0a4a3', .1, 2000)

    window.TweenMax = TweenMax
    window.scene = this.scene
    window.THREE = THREE
  }

  setSound () {
    // create an AudioListener and add it to the camera
    var listener = new THREE.AudioListener();
    this.camera.add( listener );

    // create the PositionalAudio object (passing in the listener)
    this.sound = new THREE.PositionalAudio( listener );

    // load a sound and set it as the PositionalAudio object's buffer
    var audioLoader = new THREE.AudioLoader();
    audioLoader.load('./assets/music.ogg', buffer => {
      this.sound.setBuffer(buffer)
      this.sound.setLoop(true)
      this.sound.setRefDistance(60)

      if (isObjectsLoaded) this.sound.play()
    });

    // create an object for the sound to play from
    var sphere = new THREE.SphereGeometry( 20, 32, 16 );
    var material = new THREE.MeshPhongMaterial( { color: 0xff2200 } );
    var mesh = new THREE.Mesh( sphere, material );
    mesh.position.set(0, 0, 100)
    this.scene.add( mesh );
    mesh.visible = false

    // finally add the sound to the mesh
    mesh.add( this.sound );
  }

  fadeInPlay (duration, cb) {
    const obj = { volume: 0 }

    TweenMax.to(obj, duration, {
      volume: 1,
      ease: Linear.easeNone,
      onUpdate: () => {
        this.sound.setVolume(obj.volume)
      },
      onStart: () => {
        this.sound.play()
      },
      onComplete: () => {
        if (typeof cb === 'function') cb()
      }
    })
  }

  fadeOutPlay (duration, cb) {
    const obj = { volume: 1 }

    TweenMax.to(obj, duration, {
      volume: 0,
      ease: Linear.easeNone,
      onUpdate: () => {
        this.sound.setVolume(obj.volume)
      },
      onComplete: () => {
        this.sound.pause()
        if (typeof cb === 'function') cb()
      }
    })
  }

  setScene () {
    this.scene = new THREE.Scene()

    this.scene.userData.currentTweens = [] // Store all tweens

    this.renderer = new THREE.WebGLRenderer({
      antialias: true
    })

    // this.renderer.gammaOutput = true;
    // this.renderer.gammaFactor = 2.2;
    // this.renderer.autoClear = false;
    // //

    this.renderer.setSize(window.innerWidth, window.innerHeight)
    document.getElementById('canvasContainer').appendChild(this.renderer.domElement)
  }

  addDevWorld(){
    let floor = new THREE.Mesh(
      new THREE.PlaneGeometry(10000, 10000),
      new THREE.MeshBasicMaterial({color: 'gray', side: THREE.DoubleSide})
    )
    floor.rotation.x = Math.PI * .5
    floor.position.y = -200
    this.scene.add(floor)
  }

  addWorld(){
    const self = this
    var manager = new THREE.LoadingManager();
    const preloader = document.querySelector('.Preloader')

    manager.onStart = function ( url, itemsLoaded, itemsTotal ) {
      // const percentage = Math.round(itemsLoaded * 100 / itemsTotal)
      // preloader.querySelector('.Preloader-value').text = percentage
    };

    manager.onLoad = () => {
      isObjectsLoaded = true


      TweenMax.to('.Preloader', 1, {
        autoAlpha: 0,
        onComplete: () => {
          this.intro.animEnter()
        }
      })
    };

    manager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
      const percentage = Math.round(itemsLoaded * 100 / itemsTotal)
      TweenMax.to(preloader.querySelector('.preloader_bar_value'), 0.2, {width: percentage+'%'})
    };

    manager.onError = function ( url ) {
      console.log( 'There was an error loading ' + url );
    };

    const gltfLoader = new THREE.GLTFLoader()
    const objLoader = new THREE.OBJLoader(manager)
    const fbxLoader = new THREE.FBXLoader()


    objLoader.load('./models/Pont.obj', obj => {
      obj.children[0].material.map = new THREE.TextureLoader().load('./models/Back_pont.jpg')
      this.scene.add(obj)
    })

    objLoader.load('./models/_/Escaliers.obj', obj => {
      console.log(obj)
      // obj.position.set(0, 2000, -2000)
      obj.children[0].material.map = new THREE.TextureLoader().load('./models/_/Back_escaliers.jpg')

      this.scene.add(obj)
    })

    objLoader.load('./models/Farrandole.obj', obj => {

      obj.children[0].material.map = new THREE.TextureLoader().load('./models/Back_Farrandole.jpg')
      this.scene.add(obj)
    })

    objLoader.load('./models/Lightpont.obj', obj => {
      console.log(obj)

      obj.position.y = -2

      obj.children[0].material.map = new THREE.TextureLoader().load('./models/Back_Lightpont.jpg')
      this.scene.add(obj)
    })

    objLoader.load('./models/Person.obj', obj => {
      obj.traverse( function ( child ) {
        if(child instanceof THREE.Mesh)
          child.material.color.set('#e3aca2');
      });
      this.scene.add(obj)

      // this.mixer = new THREE.AnimationMixer(obj)
      // let action = this.mixer.clipAction(obj.animations[0])
      // action.play()

    })

    objLoader.load('./models/_/Terrain.obj', obj => {
      console.log(obj)
      // obj.children[0].material = new THREE.MeshPhongMaterial({side: THREE.DoubleSide})
      obj.children[0].material.map = new THREE.TextureLoader().load('./models/_/Back_Terrain.jpg')
      this.scene.add(obj)
    })

    objLoader.load('./models/Affiches.obj', obj => {
      console.log(obj, false)
      obj.children[0].material.map = new THREE.TextureLoader().load('./models/Affiches.jpg')
      this.scene.add(obj)
    })

    let skybox = new THREE.Mesh(
      new THREE.SphereBufferGeometry(10000),
      new THREE.MeshBasicMaterial({
        // color: 'green',
        fog: false,
        side: THREE.BackSide,
        map: new THREE.TextureLoader().load('./textures/Sky.jpg')
      })
    )
    skybox.rotation.y = Math.PI * .5 + Math.PI * -.01
    this.scene.add(skybox)
  }

  addSpline(){
    const self = this
    this.spline = new Spline(this.camera, this.cameraTarget, this.scene, this.steps)
    let skipStepFunc = (index, onStepSkipped = null) => {
      this.steps.show(index, onStepSkipped)
      document.body.classList.add('hasReached') // for dev
    }

    this.spline.setSteps([
      {
        at: .12,
        callback: _ => {skipStepFunc(0)}
      },
      {
        at: .45,
        callback: _ => {skipStepFunc(1)}
      },
      {
        at: .745,
        callback: _ => {skipStepFunc(2)}
      },
      {
        at: .998,
        callback: _ => {
          self.ballGame.canPlay()
          skipStepFunc(3, function(){
          document.querySelector('.steps').style.display = 'none'
          document.querySelector('.mobile-shoot').classList.add('show')
          self.cursor.toggleTargetCursor(true)

          document.querySelector('.replay-container').classList.toggle('show')
          TweenMax.staggerTo(self.retrySplit.chars, 0.3, {autoAlpha: 1, y: 0, delay: 0.5}, 0.05, _ => {
            document.querySelector('#resetScene').classList.add('show')
          })
        })}
      },
    ])
  }

  
  addLight(){
    const ambientLight = new THREE.AmbientLight(0xffffff, 1)
    this.scene.add(ambientLight)

    const directionalLight = new THREE.DirectionalLight(0xffffff, .4)
    directionalLight.position.set(0, 10000, 0)
    this.scene.add( directionalLight );
  }

  attachEvents () {

    let start = new THREE.Vector2(), end = new THREE.Vector2(), distance, direction;

    document.addEventListener('touchstart', e => {
      let x = e.touches[0].screenX || e.touches[0].pageX
      let y = e.touches[0].screenY || e.touches[0].pageY
      start.set(x, y)
    })
    document.addEventListener('touchmove', e => {
    })
    document.addEventListener('touchend', e => {
      let x = e.changedTouches[0].screenX || e.changedTouches[0].pageX
      let y = e.changedTouches[0].screenY || e.changedTouches[0].pageY

      end.set(x, y)
      distance = -(end.y - start.y)
      this.moveInSpline(distance)
    })

    if(!window.isMobile){
      console.log('mobile ' + window.isMobile)
      document.addEventListener('mousemove', this.onMouseMove.bind(this), false)
    } else {

      
      // this.controls = new THREE.DeviceOrientationControls( this.camera );

      // window.mobileOrientation = {alpha: 0, beta: 0}
      // window.addEventListener("deviceorientation", e => {

      //   let alpha = (2 * -e.alpha - 360) / 360
      //   let beta = (-e.beta / 180) * 2
      //   this.mouse.set(alpha, beta)
        
      //   this.computeVectorAroundCamera()

      //   console.log({alpha, beta})
      // }, true);
    }
    

    document.addEventListener('wheel', this.onMouseWheel.bind(this), false)
    window.addEventListener('resize', this.onResize.bind(this), false)
    // document.getElementById('resetScene').addEventListener('click', this.reset.bind(this))
  }

  removeEvents(){
    document.removeEventListener('mousemove', this.onMouseMove.bind(this), false)
    document.removeEventListener('wheel', this.onMouseWheel.bind(this), false)
    window.removeEventListener('resize', this.onResize.bind(this), false)
    // document.getElementById('resetScene').removeEventListener('click', this.reset.bind(this))
  }

  computeVectorAroundCamera(){
    let fromCameraToCameraTarget = new THREE.Vector3().subVectors(this.cameraTarget.userData.computedPosition, this.camera.position)
    fromCameraToCameraTarget.applyAxisAngle(new THREE.Vector3(0, 1, 0), HEAD_ROTATION_X * -this.mouse.x)
    // fromCameraToCameraTarget.applyAxisAngle(new THREE.Vector3(1, 0, 0), HEAD_ROTATION_Y * this.mouse.y)
    fromCameraToCameraTarget.y = 20 * -this.mouse.y

    TweenLite.to(this.cameraTarget.userData.rotationAroundCamera, .6, {
      x: fromCameraToCameraTarget.x,
      y: fromCameraToCameraTarget.y,
      z: fromCameraToCameraTarget.z,
    })
  }

  onMouseMove (e) {

    if(this.canTriggerEvent){
      this.mouse.x = (2 * e.clientX - this.window.x) / this.window.x
      this.mouse.y = (2 * e.clientY - this.window.y) / this.window.y

      this.computeVectorAroundCamera()

      // SALE
      // this.camera.rotation.x = this.mouse.y * Math.PI * .5
      // this.camera.rotation.y = this.mouse.x * Math.PI * .5
    }

  }

  moveInSpline(deltaY){
    if (this.canTriggerEvent) {
      let scrollAmount = Math.sign(deltaY) * (1 / MAX_WHEEL_COUNT) // Quantité de scroll uniforme
      // scrollAmount = e.deltaY * .00006 // En fonction de la quantité de scroll

      const prevFrame = this.frame
      const nextFrame = prevFrame + scrollAmount
      let transition = {value: prevFrame}

      let tw = TweenLite.to(transition, 2, {
        ease: Power4.easeOut,
        value: nextFrame,
        callbackScope: this,
        onUpdate(){
          this.frame = this.spline.setCamera(transition.value)
          this.computeVectorAroundCamera()
        }
      })

      // this.moveCameraHead()


      this.steps.scroll(scrollAmount)
    }
  }

  onMouseWheel (e) {
    this.moveInSpline(e.deltaY)
  }

  onResize (e) {
    const width = window.innerWidth
    const height = window.innerHeight

    this.window.set(width, height)

    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(width, height)
  }

  playScene () {
    this.animate()
  }

  stopScene () {
    cancelAnimationFrame(req)
  }

  applyCameraTargetPosition(){
    this.cameraTarget.position.copy(this.cameraTarget.userData.computedPosition)
    this.cameraTarget.position.add(this.cameraTarget.userData.rotationAroundCamera)
  }

  animate (t) {
    req = requestAnimationFrame(this.animate.bind(this))

    let deltaTime = this.clock.getDelta()

    // if(this.mixer) {
    //   this.mixer.update(deltaTime)
    // }

    this.ballGame.updatePhysics(deltaTime)


    this.applyCameraTargetPosition()

    if(this.controls){
      this.controls.update();
    }

    this.camera.lookAt(this.cameraTarget.position)
    this.renderer.render(this.scene, this.camera)
  }
}

export default Scene
