import Scene from './scene3d/Scene'
import Slider from './Slider'
import Cursor from './Cursor'
import About from './About'
import SceneSound from './Sound'
var MobileDetect = require('mobile-detect')
import SplitText from './utils/SplitText.min.js'

console.log = _ => {}

// Start 3d scene
var scene,
    aboutClass,
    cursor,
    slider

var md = new MobileDetect(window.navigator.userAgent)

if(md.mobile() !== null){
  let body = document.querySelector('body')
  body.classList.add('mobile')
  body.classList.add(md.mobile())
  TweenMax.set([document.body, '#canvasContainer', '.about', '.Preloader'], {height: window.innerHeight})
  window.isMobile = true

}

window.sound = new SceneSound()

setTimeout(_ => {
  cursor = new Cursor()
  slider = new Slider(cursor)
  aboutClass = new About(cursor)
  scene = new Scene(cursor)
}, 1000)

// Start 3d Scene with postprocessing
// SceneWithPostprocessing.start()

import FogTest from './scene3d/FogTest'
import TweenLite from 'gsap/TweenLite';
import { TweenMax } from 'gsap';

// FogTest.start()

// favicon ------------
let favicon = document.querySelector("link[rel*='icon']"),
    favIndex = 1

setInterval(_ => {
  favIndex++
  favicon.href = `./favicon/favicon${favIndex}.png`
  if(favIndex === 3) favIndex = 0
}, 500)

/* ----------------------
        CLASSEMENT
-------------------------- */
// let items = document.querySelectorAll('.classement li'),
//     number = document.querySelector('.classementNumber')

// const setActiveItem = (e) => {
//   let item = e.target

//   for(let i = 0; i < items.length; i++)
//     items[i].classList.remove('active')

//   item.classList.add('active')
//   // number.innerHTML = item.getAttribute('data-id')
// }

// for(let i = 0; i < items.length; i++)
//   items[i].addEventListener('mouseover', setActiveItem)

// -----------------------
const buildSoundWave = (w, h) => {
  const m = 0.512286623256592433

  const pathData = [
    'M', 0, h * 0.625,
    'c',  0.25 * h * m, 0,
    0.25 * h * (1 - m), -0.25 * h,
    0.25 * h, -0.25 * h,
    's', 0.25 * h * (1 - m), 0.25 * h,
    0.25 * h, 0.25 * h,
    's', 0.25 * h * (1 - m), -0.25 * h,
    0.25 * h, -0.25 * h,
    's', 0.25 * h * (1 - m), 0.25 * h,
    0.25 * h, 0.25 * h,
    's', 0.25 * h * (1 - m), -0.25 * h,
    0.25 * h, -0.25 * h,
    's', 0.25 * h * (1 - m), 0.25 * h,
    0.25 * h, 0.25 * h,
    's', 0.25 * h * (1 - m), -0.25 * h,
    0.25 * h, -0.25 * h,
    's', 0.25 * h * (1 - m), 0.25 * h,
    0.25 * h, 0.25 * h,
    's', 0.25 * h * (1 - m), -0.25 * h,
    0.25 * h, -0.25 * h,
    's', 0.25 * h * (1 - m), 0.25 * h,
    0.25 * h, 0.25 * h,
    's', 0.25 * h * (1 - m), -0.25 * h,
    0.25 * h, -0.25 * h,'s', 0.25 * h * (1 - m), 0.25 * h,
    0.25 * h, 0.25 * h,
    's', 0.25 * h * (1 - m), -0.25 * h,
    0.25 * h, -0.25 * h
  ].join(' ')

  document.querySelector('.Nav-soundControl-wave').setAttribute('d', pathData)
}

buildSoundWave(90, 60)

let isSoundTransitionRunning = false


let hidefor3dDev = Array.from(document.querySelectorAll('.hidefor3dDev'))
hidefor3dDev.map(item => item.style.display = 'none')

// Hide for 3d dev
window.isAboutVisible = false

TweenMax.set(['.Nav-about', '.Nav-soundControl', '.Nav-logoUN'], {
  autoAlpha: 0
})

let aboutNav = document.querySelector('.Nav-about'),
    aboutText = aboutNav.querySelector('.aboutText'),
    closeText = aboutNav.querySelector('.closeText'),
    aboutTextSplit = new SplitText(aboutText, {type: 'chars', charsClass: 'letter'}),
    aboutCloseSplit = new SplitText(closeText, {type: 'chars', charsClass: 'letter'}),
    aboutBtnTl = new TimelineMax({paused: true})

aboutBtnTl.staggerTo(aboutTextSplit.chars, 0.3, {autoAlpha: 0, y: -10}, 0.05)
.set(aboutText, {display: 'none'})
.set(closeText, {display: 'block'})
.staggerTo(aboutCloseSplit.chars, 0.3, {autoAlpha: 1, y: 0}, 0.05)

document.querySelector('.Nav-about a').addEventListener('click', e => {
  e.preventDefault()
  isAboutVisible = !isAboutVisible

  aboutNav.classList.toggle('active')

  if(aboutNav.classList.contains('active')){

    // enter
    aboutBtnTl.play()
    scene.enterAboutPage(function(){
      // temp fix / put this into previous callback
      TweenMax.to('.about', 0.5, {
        autoAlpha: 1,
        onComplete: _ => {
          aboutClass.init()
          scene.stopScene()
        }
      })

    })


  }
  else{
    // exit
    scene.exitAboutPage(function(){
      TweenMax.to('.about', 0.5, {
        autoAlpha: 0,
        onStart: () => {
          scene.playScene()
          aboutBtnTl.reverse()
        },
        onComplete: _ => {
          aboutClass.destroy()
        }
      })
    })
  }
})
