export default class Sound {
  constructor() {
    this.isTransitionRunning = false
    this.isPlaying = false
    this.sound = document.querySelector('.Music')
    this.audioController = document.querySelector('.Nav-soundControl')

    this.audioController.addEventListener('click', () => {
      if (!this.isTransitionRunning) {
        if (this.isPlaying) {
          this.fadeOutPlay()
        } else {
          this.fadeInPlay()
        }
      }
    })
  }

  fadeInPlay (duration = 1, cb) {
    const obj = { volume: 0 }

    TweenMax.to(obj, duration, {
      volume: 0.75,
      ease: Linear.easeNone,
      onUpdate: () => {
        this.sound.volume = obj.volume
      },
      onStart: () => {
        this.isTransitionRunning = true
        this.sound.volume = 0
        this.sound.play()
        this.audioController.classList.remove('Nav-soundControl--off')
        this.audioController.classList.add('Nav-soundControl--on')
      },
      onComplete: () => {
        this.isPlaying = true
        this.isTransitionRunning = false
        if (typeof cb === 'function') cb()
      }
    })
  }

  fadeOutPlay (duration = 1, cb) {
    const obj = { volume: 0.75 }

    TweenMax.to(obj, duration, {
      volume: 0,
      ease: Linear.easeNone,
      onUpdate: () => {
        this.sound.volume = obj.volume
      },
      onStart: () => {
        this.isTransitionRunning = true
        this.sound.volume = 1
        this.audioController.classList.remove('Nav-soundControl--on')
        this.audioController.classList.add('Nav-soundControl--off')
      },
      onComplete: () => {
        this.isPlaying = false
        this.isTransitionRunning = false
        this.sound.pause()
        if (typeof cb === 'function') cb()
      }
    })
  }
}