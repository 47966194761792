import { TweenMax, Elastic } from 'gsap'
import SplitText from './utils/SplitText.min.js'

class Steps {
  constructor () {
    this.stepsEl = document.querySelector('.steps')
    this.stepBg = this.stepsEl.querySelector('.background')
    this.stepsList = this.stepsEl.querySelectorAll('.step')

    this.reset()
  }

  reset(){
    this.timelines = []
    this.isVisible = false
    this.isPlaying = false
    this.activeIndex = null
    this.activeCallback = null

    TweenMax.set([this.stepBg, this.stepsEl, this.stepsList], {clearProps: 'all'})

    for(let i = 0; i < this.stepsList.length; i++){
      this.stepsList[i].classList.remove('active')
      this.setTimelines(this.stepsList[i])
    }
  }

  setTimelines(item){
    const self = this
    let tl = new TimelineMax({
          paused: true,
          onComplete: _ => {
            window.SKIP()
            if(self.activeCallback) {
              self.activeCallback()
              self.activeCallback = null // reset onStepSkipped callback
            }
          }
        }),
        splitText = new SplitText(item.querySelector('.step-text'), {type: 'chars, words', charsClass: 'letter'}),
        distance = 50,
        time = 2,
        gap = 0.04

    TweenMax.set(splitText.chars, {autoAlpha: 0, y: distance})
    TweenMax.set(item.querySelector('.step-scroll'), {
      autoAlpha: 0
    })

    tl
    .addLabel('start')
    .set(this.stepsEl, {autoAlpha: 1})
    .call(_ => {
      let activeStep = item
      activeStep.classList.toggle('active')
      TweenMax.to(this.stepBg, 1, {autoAlpha: 0.45})
    })
    .staggerTo(splitText.chars, time, {autoAlpha: 1, y: 0, ease: Elastic.easeOut.config(1, 0.75)}, gap)
    .to(item.querySelector('.dot'), .3, { autoAlpha: 1 }, '-=.8')
    .to(item.querySelector('.step-scroll'), .3, { autoAlpha: 1 }, '-=1.5')
    .addLabel('visible')
    // .call(_ => {
    //   console.log('call 0')
    //   TweenMax.to(this.stepBg, 1, {autoAlpha: 0})
    // })
    .to(this.stepBg, 0.3, {autoAlpha: 0}, '+=1')
    .staggerTo(splitText.chars, time/3, {
      onStart: () => {
        TweenMax.to(item.querySelector('.dot'), .3, { autoAlpha: 0 })
      },
      autoAlpha: 0, y:-distance, ease: Elastic.easeOut.config(1, 0.75)}, gap / 4)
    .to(item.querySelector('.step-scroll'), .5, { autoAlpha: 0 }, '-=.8')
    .set(this.stepsEl, {autoAlpha: 0})
    .addLabel('end')

    this.timelines.push(tl)
  }

  scroll(value){
    if(!this.isVisible || this.timelines[this.activeIndex].isActive())
      return

    if(this.timelines[this.activeIndex].currentLabel() === 'visible'){
      if(value > 0)
        this.timelines[this.activeIndex].tweenTo('end')
      else
        this.timelines[this.activeIndex].tweenTo('start')
    }
    else
      this.timelines[this.activeIndex].tweenTo('visible')
  }

  show(index, onStepSkipped = null) {
    window.stopScroll = true
    this.activeIndex = index
    this.activeCallback = onStepSkipped
    this.isVisible = true
    this.timelines[index].tweenTo('visible')
  }

  hide() {
    this.isVisible = false
    window.stopScroll = false
  }
}

export default Steps
